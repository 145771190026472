// @flow
import React from 'react';
import styles from './Blog.module.scss';

const Blog = ({ blog }) => {

  const companyImageSrc = `/media/${blog.node.companyName.toLowerCase().replace(/ /g,"").replace(/\//g,"")}.jpg`

  return <div className={`card-content ${styles['blog__card-content']}`}>
    <div className={`media ${styles['blog__card-media']}`}>
      <div className='media-left'>
        <figure className={`image ${styles['blog__logo']}`}>
          <img src={companyImageSrc}/>
        </figure>
      </div>
      <div className={`media-content ${styles['blog__company-content']}`}>
        <h3>
          <a className={`title is-4 ${styles['blog__company-name']}`} target='_blank' rel='noopener noreferrer' href={blog.node.blogUrl}>
            {blog.node.companyName} Tech Blog
          </a>
        </h3>
        <p className={`is-6 ${styles['blog__company-description']}`}>
          {blog.node.companyDescription}
        </p>
        <p>
          <a className={`is-6 ${styles['blog__company-count']}`} target='_blank' rel='noopener noreferrer' href={blog.node.blogUrl}>
          ⌨️ <span>{blog.totalCount}</span> articles
          </a>
          {blog.node.sponsorUrl ?
            <a className={`is-6 ${styles['blog__company-jobs']}`} target='_blank' rel='noopener noreferrer' href={blog.node.sponsorUrl}>
            🚀 <span>{blog.node.sponsorText} </span>
            </a> : null}
        </p>
      </div>
    </div>
  </div>
}

export default Blog;