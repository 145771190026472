// @flow
import React, {Fragment, useState, useEffect} from 'react';
import moment from 'moment';
import styles from './Articles.module.scss';
import { Link } from 'gatsby';

const _ = require('lodash');

const Articles = ({ blog }) => {

  const latestArticle = blog.edges[0].node;
  const latestArticleCategoryPath = `/${_.kebabCase(latestArticle.category.toLowerCase())}`
  const latestArticleDate = moment(latestArticle.postPublishedDate, 'YYYY/MM/DD').format('D MMMM YYYY');

  return <Fragment>
    <div className={styles['articles__article-last']}>
      <div className={`${styles['articles__article-category']}`}>
        <Link to={latestArticleCategoryPath}>
          {latestArticle.category}
        </Link>
      </div>
      <div className={`${styles['articles__article-date']}`}>
        - {latestArticleDate}
      </div>
    </div>
    <div className={`content ${styles['articles__article']}`}>
      <div className='is-size-6'>
        <a className={`${styles['articles__article-title']}`} href={latestArticle.postUrl} target='_blank' rel='noopener noreferrer'>
          {latestArticle.postTitle}
        </a>
      </div>
    </div>
  </Fragment>
}

export default Articles;