// @flow
import React, { useState, useEffect } from 'react';
import Home from '../components/Home';
import Layout from '../components/Layout';
import { graphql } from 'gatsby'
import moment from 'moment';


const mergeBlogsArticles = (blogs, articles) =>
	blogs.map(blog => ({
		...blog,
		...articles.find((article) => (article.fieldValue === blog.node.blogUrl) && blog),
	}));

const sortBlogs = (blogs) => blogs.sort(function(a, b){
	// console.log(a, a.edges);
	// console.log(b, b.edges);

	return moment(b.edges[0].node.postPublishedDate, 'YYYY/MM/DD') - moment(a.edges[0].node.postPublishedDate, 'YYYY/MM/DD');
});

const HomeTemplate = ({data}) => {
	const mergedBlogs = mergeBlogsArticles(data.blogs.edges, data.articles.group);
	const sortedBlogs = sortBlogs(mergedBlogs);

	return (
		<Layout>
			<Home blogs={sortedBlogs}/>
		</Layout>
	)
};

export const query = graphql`
  query {
    blogs: allGoogleSpreadsheetBlogsBlogs(filter: {
			online: {eq: "1"}
		}) {
      edges {
        node {
          companyUrl
					companyName
					companyDescription
					blogUrl
					sponsorUrl
					sponsorText
        }
      }
		}
		articles: allGoogleSpreadsheetBlogsArticles(
			filter: {
        online: {eq: "1"}
      },
			sort: {
				fields: [postPublishedDate]
				order: DESC
			}
		) {
			group(field: blogUrl, limit: 1) {
				fieldValue
				totalCount
				edges {
					node {
						postUrl
						postTitle
						category
						postPublishedDate
					}
				}
			}
		}
  }
`;

export default HomeTemplate;
