// @flow
import React, {Fragment} from 'react';
import styles from './Home.module.scss';
import Blog from './Blog';
import Articles from './Articles';
import Header from '../Header';
import Footer from '../Footer';
import MailChimpWrapper from '../MailChimpWrapper';
import useCategoriesList from '../../hooks/use-categories-list';
import { Link } from 'gatsby';

const _ = require('lodash');

const Home = ({ blogs }) => {
  const mapping_dates = {
    0: 'Published in May',
    7: 'Published in April',
    23: 'Published in March',
    32: 'Published in February',
    41: 'Published in January',
    45: 'Previously',
  }

  const categories = useCategoriesList();

  function getLabel(index) {
    if (index == 0) {
      return (
        <p className={`${styles['home__label-first']}`}> {mapping_dates[index]}</p>
      )
    }
    let testimonials = [
      //  <div>
      //    <figure className={`image ${styles['home__sponsor-logo']}`}>
      //     <img src='./media/talent.png'/><span>SPONSORED BY talent.io</span>
      //   </figure>
      //   <div className={`${styles['home__sponsor-text']}`}>
      //     <div>
      //     Do you know where you stand salary-wise? talent.io analysed over 100,000+ interview offers made on their platform to share a clear picture of the European tech market.
      //     </div>
      //     <br></br>
      //     <a className={`content ${styles['home__sponsor-link']}`} href='https://join.talent.io/tech-salary-report-en?utm_source=ari-guriosity&utm_medium=partnership&utm_campaign=website-03-02-22'>Download the tech salary report</a>
      //   </div>
      // </div>,
      <div>
        <div className={`${styles['home__testimonial-content-quote']}`}>
          Thanks to Guriosity and its weekly rendez-vous, I never miss on interesting tech articles without infinitelly scrolling through Twitter, Linkedin or RSS feeds!
        </div>
        <div className={`content ${styles['home__testimonial-content-author']}`}>
          – <a className={`content ${styles['home__testimonial-content-link']}`} href='https://www.linkedin.com/in/erwan-ben-souiden-8b8084152' target='_blank' rel='noopener noreferrer'>
            Erwan Ben Souiden</a>, DevOps at Toucan Toco
        </div>
      </div>,
      <div>
        <div className={`${styles['home__testimonial-content-quote']}`}>
          Tech blogs are an awesome source of learning, but they are hard to keep track of! With Guriosity, it all comes to me!
        </div>
        <div className={`content ${styles['home__testimonial-content-author']}`}>
          – <a className={`content ${styles['home__testimonial-content-link']}`} href='https://www.linkedin.com/in/paulvidal96' target='_blank' rel='noopener noreferrer'>
            Paul Vidal</a>, CTO at Collective
        </div>
      </div>,
      <div>
        <div className={`${styles['home__testimonial-content-quote']}`}>
          French companies have a lot to share and Guriosity is a great way to filter things out, thanks to a qualitative curation done by Ari! It's the first place I go to check out for new ideas.
        </div>
        <div className={`content ${styles['home__testimonial-content-author']}`}>
          – <a className={`content ${styles['home__testimonial-content-link']}`} href='https://www.linkedin.com/in/mickaelarcos' target='_blank' rel='noopener noreferrer'>
            Mickaël Arcos</a>, CTO at Vialma
        </div>
      </div>,
      <div>
        <div className={`${styles['home__testimonial-content-quote']}`}>
          I spent 30 minutes on guriosity.com. I discovered companies I didn't even know about!
        </div>
        <div className={`content ${styles['home__testimonial-content-author']}`}>
          – <a className={`content ${styles['home__testimonial-content-link']}`} href='https://www.linkedin.com/in/benoitlarroque/' target='_blank' rel='noopener noreferrer'>
             Benoît Larroque</a>, Engineering Manager at Doctolib
        </div>
      </div>,
      <div>
        <div className={`${styles['home__testimonial-content-quote']}`}>
          I believe that all tech teams have tremendous value to share. With Guriosity, I want to encourage more tech teams to share their learnings and make sure they are read by you.
        </div>
        <div className={`content ${styles['home__testimonial-content-author']}`}>
          – <a className={`content ${styles['home__testimonial-content-link']}`} href='/about'>
            Ari Bajo</a>, Founder of Guriosity
        </div>
      </div>
    ]
    if (mapping_dates.hasOwnProperty(index)) {
      let testimonial_i = Object.keys(mapping_dates).indexOf(index.toString()) - 1;
      return (
        <div>
          <div className={`${styles['home__testimonial']}`}>
             <blockquote className={`${styles['home__testimonial-content']}`}>
                {testimonials[testimonial_i % testimonials.length]}
                {testimonial_i < 0 ? null : <MailChimpWrapper/>}
            </blockquote>
          </div>
          <div className={`${styles['home__label']}`}>{mapping_dates[index]}</div>
        </div>
      )
    }
  }

  return (
    <Fragment>
      <Header/>
      <section className='hero'>
        <div className={ `hero-body ${styles['home__hero']}` }>
          <div className='container'>
            <h1 className={ `title has-text-centered ${styles['home__hero-title']}` }>
              The best tech teams share their learnings
            </h1>
            <h2 className={ `has-text-centered ${styles['home__hero-subtile']}` }>
              Get a selection of articles from <br/><a className={ `${styles['home__orange']}` } href='#blogs'>🏆 95 tech blogs</a> by French companies,<br/> every Thursday.
            </h2>
            <MailChimpWrapper/>
            <div className={`${styles['home__hero-pitch']}`}>
              From <a href='/about/'>Ari</a>, to 700+ developers. No spam.
            </div>
          </div>
        </div>
      </section>
      <div className={`${styles['home']}`}>
        <div id='blogs' className={`${styles['home__anchor']}`}>
          <div className={`is-hidden-mobile ${styles['home__kpi']}`}>
            <div className={`is-centered is-hidden-mobile ${styles['home__kpi-label']}`}>FILTER ARTICLES BY CATEGORY</div>
            <div className={`is-centered is-hidden-mobile ${styles['home__categories']}`}>
            {categories.map(category => (
              <Link
                to={`/${_.kebabCase(category.fieldValue.toLowerCase())}/`}
                className={`button ${styles['home__category']} `}>
                {category.fieldValue}
              </Link>
            ))}
          </div>
          </div>
        </div>
      {
        blogs.map((blog, index) => {
          return (
            <Fragment key={blog.node.companyName}>
              {getLabel(index)}
              <div className={ `card ${styles['home__card']} ${blog.node.sponsorUrl ? styles['home__hiring'] : null} ` }>
                <div className='columns'>
                  <div className={`column ${styles['home__blog']}`}>
                    <Blog blog={blog}></Blog>
                  </div>
                  <div className={`column ${styles['home__articles']}`}>
                    <Articles blog={blog}></Articles>
                  </div>
                </div>
              </div>
            </Fragment>
          )
        })
      }
      <div className={`${styles['home__add']}`}>
        <a className={`${styles['home__add-link']}`} href='https://forms.gle/vvdgDfjds4ETZTxi7' target="_blank">
          ➕ Suggest a software engineering blog
        </a>
      </div>
      </div>
      <br/>
      <Footer/>
    </Fragment>
  );
}

export default Home;
